import './Gallery.css';
import logo from '../assets/logo.svg';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { PiPackageBold } from 'react-icons/pi';
import { LuPackageOpen} from 'react-icons/lu';
import ButtonToTop from './ButtonToTop';
import React, { useState, useRef, useEffect } from 'react';
import LazyLoad from 'react-lazyload';
import Footer from '../footer/Footer';

import { MdArrowBackIos } from 'react-icons/md';
import { MdArrowForwardIos } from 'react-icons/md';
import { BsXLg } from 'react-icons/bs';
import { PiPackageFill } from 'react-icons/pi';

import img1 from '../assets/img1.webp';
import img2 from '../assets/img2.webp';
import img3 from '../assets/img3.webp';
import img4 from '../assets/img4.webp';
import img5 from '../assets/img5.webp';
import img6 from '../assets/img6.webp';
import img7 from '../assets/img7.webp';
import img8 from '../assets/img8.webp';
import img9 from '../assets/img9.webp';
import img10 from '../assets/img10.webp';
import img11 from '../assets/img11.webp';
import img12 from '../assets/img12.webp';
import img13 from '../assets/img14.webp';
import img14 from '../assets/img27.webp';
import img15 from '../assets/img15.webp';
import img16 from '../assets/img16.webp';
import img17 from '../assets/img17.webp';
import img18 from '../assets/img18.webp';
import img19 from '../assets/img19.webp';
import img20 from '../assets/img20.webp';
import img21 from '../assets/img21.webp';
import img22 from '../assets/img22.webp';
import img23 from '../assets/img23.webp';
import img24 from '../assets/img24.webp';
import img25 from '../assets/img25.webp';
import img26 from '../assets/img26.webp';
import img28 from '../assets/img28.webp';
import img29 from '../assets/img29.webp';
import img30 from '../assets/img30.webp';
import img31 from '../assets/img31.webp';

function Gallery() {

const galleryimages = [

    {id: 1, imgsrc: img1},
    {id: 2, imgsrc: img2},
    {id: 3, imgsrc: img3},
    {id: 4, imgsrc: img4},
    {id: 5, imgsrc: img5},
    {id: 6, imgsrc: img6},
    {id: 7, imgsrc: img7},
    {id: 8, imgsrc: img8},
    {id: 9, imgsrc: img9},
    {id: 10, imgsrc: img10},
    {id: 11, imgsrc: img11},
    {id: 12, imgsrc: img12},
    {id: 13, imgsrc: img13},
    {id: 14, imgsrc: img14},
    {id: 15, imgsrc: img15},
    {id: 16, imgsrc: img16},
    {id: 17, imgsrc: img17},
    {id: 18, imgsrc: img18},
    {id: 19, imgsrc: img19},
    {id: 20, imgsrc: img20},
    {id: 21, imgsrc: img21},
    {id: 22, imgsrc: img22},
    {id: 23, imgsrc: img23},
    {id: 24, imgsrc: img24},
    {id: 25, imgsrc: img25},
    {id: 26, imgsrc: img26},
    {id: 30, imgsrc: img30},
    {id: 28, imgsrc: img28},
    {id: 31, imgsrc: img31},
    {id: 29, imgsrc: img29}
];

const [hide, show] = useState(false);

const [clickedImg, setClickedImg] = useState(null);
const [currentIndexx, setCurrentIndexx] = useState(null);
const [hidden, unsetHidden] = useState(true);
const [scroll, noScroll] = useState(true);

const toggle = () => {
    unsetHidden(!hidden);
};

const scrollToggle = () => {
    noScroll(!scroll);
};

useEffect (() => {
    if (scroll) {
        document.body.style.overflow = "scroll";
    } else {
        document.body.style.overflow = "hidden"
    }

}, [scroll]);

const handleClick = (item, index) => {
    setCurrentIndexx(index);
    setClickedImg(item.imgsrc);
    toggle(unsetHidden);
    scrollToggle(noScroll);
};

const secondHandleClick = () => {
    toggle(unsetHidden);
    scrollToggle(scroll);
}

const timerRef = useRef(null);

const goToNext = () => {
    const totalLength = galleryimages.length;
    if(currentIndexx + 1 >= totalLength) {
        setCurrentIndexx(0);
        const newImg = galleryimages[0].imgsrc;
        setClickedImg(newImg);
        return;
    }
    

    const newIndexx = currentIndexx + 1;
    const newImg = galleryimages.filter((item) => {
        return galleryimages.indexOf(item) === newIndexx;
    });
    const newItem = newImg[0].imgsrc;
    setClickedImg(newItem);
    setCurrentIndexx(newIndexx);
    };

const goToPrevious = () => {
    const totalLength = galleryimages.length;
    if(currentIndexx === 0) {
        setCurrentIndexx(totalLength - 1);
        const newImg = galleryimages[totalLength - 1].imgsrc;
        setClickedImg(newImg);
        return;
    }
    

    const newIndexx = currentIndexx - 1;
    const newImg = galleryimages.filter((item) => {
        return galleryimages.indexOf(item) === newIndexx;
    });
    const newItem = newImg[0].imgsrc;
    setClickedImg(newItem);
    setCurrentIndexx(newIndexx);
    };

return (

<div>

    <div className="galleryWrapper noScroll">

        <section>
            <nav className="galleryMobileNav">

                <div className="gMNClosed">
                    <h1 id="gMNHeader">Comfort Home Decor</h1>
                    <PiPackageFill
                        className="gMNClosedIcon"
                        onClick={()=>show(!hide)}
                    />
                </div>

                { hide ?

                <div className="gMNOpened">
                    <LuPackageOpen 
                        className="gMNOpenIcon"
                        onClick={()=>show(false)}
                    />
                    <ul>
                        <li className="gMNNavItem">
                        <Link className="gMNNavLink"
                            to="/">
                            HOME
                        </Link>
                        </li>
                        <li className="gMNNavItem">
                        <Link className="gMNNavLink" 
                            to="/services">
                            SERVICES
                        </Link>
                        </li>
                        <li className="gMNNavItem">
                        <Link className="active"
                            onClick={()=>show(false)}
                            to="/gallery">
                            GALLERY
                        </Link>
                        </li>
                        <li className="gMNNavItem">
                        <HashLink className="gMNNavLink"
                            smooth to="/#about">
                            ABOUT US
                        </HashLink>
                        </li>
                    </ul>
                </div>
                : null}

            </nav>

            <nav className="galleryTabletPlusNav">
                <div className="gTPNLinks">
                    <ul className="gTPNLinks">
                        <li className="gTPNItem">
                        <Link className="gTPNLink"
                            to="/">
                            HOME
                        </Link>
                        </li>

                        <li className="gTPNItem">
                        <Link className="gTPNLink" 
                            to="/services">
                            SERVICES
                        </Link>
                        </li>

                        <li className="gTPNItemActive">
                        <Link className="gTPNLinkActive"
                            to="/gallery">
                            GALLERY
                        </Link>
                        </li>

                        <li className="gTPNItem">
                        <HashLink className="gTPNLink"
                            smooth to="/#about">
                            ABOUT US
                        </HashLink>
                        </li>
                        
                    </ul>
                </div>
                <div className = "gTPNHeader">
                    <h1>Comfort Home Decor</h1>
                </div>
            </nav>
        </section>
        {/*......images section */}
        <section classname= "gallery">
            <div className="galleryGridContainer">

                {galleryimages.map((item, index) => {
                    return(
                        <div 
                            key={index}
                            className="galleryGridItem"
                        >
                                <img
                                    className = "style"
                                    src={item.imgsrc} 
                                    onClick={()=>handleClick(item, index)}
                                    fetchpriority = "high"
                                    loading="lazy"
                                    // style={{width: '233px', height: '248px', objectFit: 'cover'}}
                                />
                        </div>
                    )
                    }
                    )
                };

                {
                    {clickedImg} && (
                        <div 
                            className={hidden ? "hideSlider" : "gallerySliderContainer"}
                        >
                            <div id="exitButton">
                                <BsXLg 
                                onClick={()=>secondHandleClick()}
                                />
                            </div>
                                <MdArrowBackIos class="leftarrow" onClick={goToPrevious}/>
                                    <div className="gallerySlideStyles" parentWidth = {500}>
                                        <img className="gallerySliderImage" src={clickedImg}></img>
                                    </div>
                                <MdArrowForwardIos class="rightarrow" onClick={goToNext}/>
                        </div>
                    )
                }

            </div>
            <ButtonToTop />
        </section>
    </div>
        <footer className="galleryFooter">
            <Footer />
        </footer>
</div>

);
}

export default Gallery;