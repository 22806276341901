import './Footer.css';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Footer () {
    return (
    <div className="chd-footer">
        {/* <div>Footer</div> */}
        <div id='contact'>
            <p> &#169; 2024 by Comfort Home Decor</p>
            <p>Website created by <span>BeyondSightSolutions</span></p>
        </div>
    </div>

)}

export default Footer;