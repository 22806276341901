import React, { useState, useEffect, useRef, useCallback } from 'react';
import './Slider.css';
import ServicesRabbit from './ServicesRabbit';
import GalleryRabbit from './GalleryRabbit';
import { MdArrowBackIos } from 'react-icons/md';
import { MdArrowForwardIos } from 'react-icons/md';
import { SlArrowLeft, SlArrowRight } from 'react-icons/sl';


function Slider() {

    const slides = [
        {id: 0, src: <ServicesRabbit/>, description: "services"},
        {id: 1, src: <GalleryRabbit/>, description: "gallery"},
    ]

    const [currentIndex, setCurrentIndex] = useState(0);

    const slideItem = slides[currentIndex];

    const timerRef = useRef(null);

    const goToPrevious = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? slides.length -1 : currentIndex -1;
        setCurrentIndex(newIndex);
    };

    const goToNext = useCallback(() => {
        const isLastSlide = currentIndex === slides.length -1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    }, [currentIndex, slides]);
    
    
    return (

        <div className="sliderContainer">

            <div className="slideStyles" >
                <div className="slideItem" id="services">
                    {slides[0].src}
                </div>
                <div className="slideItem" id="gallery">
                    {slides[1].src}
                </div>
            </div>

        </div>
        

    );

}

export default Slider;