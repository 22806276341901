import React, { useState, useEffect, useRef, useCallback } from 'react';
import './ServicesRabbit.css';
import { PiArrowRightThin } from "react-icons/pi";
import { Link } from 'react-router-dom';
import revamp from '../assets/revamp.webp';

function ServicesRabbit() {

    const [transform, setTransform] = useState(200);

    useEffect(() => {
      const slideEffect = setInterval(() => {
      if (transform > 0) {
          setTransform(transform - 1);
      } else {
          clearInterval(slideEffect);
      }
      }, 3);

    return () => clearInterval(slideEffect);
    }, [transform]);

    return (
        <div>
            <div className="services-rabbit"
              >
              <h3 className="s-h3">Need a Revamp?</h3>
              <h2 className="s-h2">See what we offer...</h2>
              <div className="s-pane">
                <img id="s-image" src={revamp}></img>
              </div>
              <Link className="s-button" 
                    to="/services">
                      OUR SERVICES
              </Link>
            </div>
        </div>
    );
}

export default ServicesRabbit;