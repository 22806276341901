import './Services.css';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { PiPackageBold } from 'react-icons/pi';
import { LuPackageOpen} from 'react-icons/lu';
import ButtonToTop from './ButtonToTop';
import React, { useState, useRef, useEffect } from 'react';
import LazyLoad from 'react-lazyload';
import Footer from '../footer/Footer';
import Form from './Form';

import { MdArrowBackIos } from 'react-icons/md';
import { MdArrowForwardIos } from 'react-icons/md';
import { BsXLg } from 'react-icons/bs';
import { PiPackageFill } from 'react-icons/pi';


function Services() {

const [hide, show] = useState(false);

return (
	<div>
		<div className="servicesWrapper">
			<section className = "servicesNavbarContainer">
				<nav className="servicesMobileNav">

					<div className="sMNClosed">
						<h1 id="sMNHeader">Comfort Home Decor</h1>
						<PiPackageFill
							className="sMNClosedIcon"
							onClick={()=>show(!hide)}
						/>
					</div>

					{ hide ?

					<div className="sMNOpened">
						<LuPackageOpen 
							className="sMNOpenIcon"
							onClick={()=>show(false)}
						/>
						<ul>
							<li className="sMNNavItem">
							<Link className="sMNNavLink"
								to="/">
								HOME
							</Link>
							</li>
							<li className="sMNNavItem">
							<Link className="active"
								to="/services">
								SERVICES
							</Link>
							</li>
							<li className="sMNNavItem">
							<Link className="sMNNavLink" 
								onClick={()=>show(false)}
								to="/gallery">
								GALLERY
							</Link>
							</li>
							<li className="sMNNavItem">
							<HashLink className="sMNNavLink"
								smooth to="/#about">
								ABOUT US
							</HashLink>
							</li>
						</ul>
					</div>
					: null}

				</nav>

				<nav className="servicesTabletPlusNav">
					<div className="sTPNLinks">
						<ul className="sTPNLinks">
							<li className="sTPNItem">
							<Link className="sTPNLink"
								to="/">
								HOME
							</Link>
							</li>



							<li className="sTPNItemActive">
							<Link className="sTPNLinkActive" 
								to="/services">
								SERVICES
							</Link>
							</li>

							<li className="sTPNItem">
							<Link className="sTPNLink"
								to="/gallery">
								GALLERY
							</Link>
							</li>

							<li className="sTPNItem">
							<HashLink className="sTPNLink"
								smooth to="/#about">
								ABOUT US
							</HashLink>
							</li>
							
						</ul>
					</div>
					<div className = "sTPNHeader">
						<h1>Comfort Home Decor</h1>
					</div>
				</nav>
			</section>
			<section className = "servicesHeroContainer">
				<div className = "servicesHero">
					<h1>Our Services</h1>
					<p>
						Thanks for visiting our site!
						Are you looking for help designing or renovating your space?
						Check out our services below to see how we can help you.
						Check out our gallery to see our work!
					</p>
				</div>
			</section>
			<section className = "servicesServicesContainer">
				<div className = "servicesServicesCard" id="interior">
					<div className = "servicesItemDescription">
						<h3>Interior Design</h3>
						<p>Love where you live!</p>
					</div>
				</div>
				<div className = "servicesServicesCard" id="outdoor">
					<div className = "servicesItemDescription">
						<h3>Outdoor Living</h3>
						<p>Spruce your outdoors!</p>
					</div>
				</div>
				<div className = "servicesServicesCard" id="remodel">
					<div className = "servicesItemDescription">
						<h3>Re-Design & Remodel</h3>
						<p>Transform your space!</p>
					</div>
				</div>
			</section>
			<section className = "servicesConsultContainer">

				<Form />
				
			</section>
			<div className="buttonScrollShow">
					<ButtonToTop />
			</div>
			<footer className="servicesFooter">
				<Footer />
			</footer>
		</div>
		<script src="https://smtpjs.com/v3/smtp.js"></script>
	</div>
);
}

export default Services;