import './Form.css';
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

function Form () {

  const form = useRef();
  const [messageSent, setMessageSent] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      'service_ygkfx7s',
      'template_50kgfrk',
      form.current,
      'zFLZH29bRNDazrYni'
      ).then((result) => {
        setMessageSent(true);  
        console.log(result.text);
        console.log("message sent");
      }, (error) => {
          console.log(error.text);
          console.log("message in error");
      });
  };
  
  return (

    <form ref={form} className = "servicesForm" onSubmit={sendEmail}>
      <h2>Get a Consult</h2>
      <p>Fill out the information below so we can get in contact with you as soon as possible!</p>
      <input type="text" name="user_name" id = "name" placeholder="First Name *" required></input>
      {/* <input type="text" id = "lastName" placeholder="Last Name"></input> */}
      <input type="email" name="user_email" id = "email" placeholder="Email Address *" required></input>
      <textarea type="text"  id = "message" name="message" placeholder="Tell us about your space" />
      <button type="submit" value="Send" id = "send"> Send </button>
      {messageSent && <p>Thanks for submitting!</p>}
    </form>

  );

}


export default Form;

