import React, { useState, useEffect, useRef } from 'react';
import './Landing.css';
import Navbar from'./components/Navbar';
import livingroom from './assets/livingroom.webp';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { PiPackageFill } from 'react-icons/pi';
import { LuPackageOpen} from 'react-icons/lu';
import Slider from './slider/Slider';
import About from './about/About';
import Footer from './footer/Footer';
import LoadingBar from 'react-top-loading-bar';

function Landing() {

  const [show, setShow] = useState(false);

  const closestyle = { 
    tranform: "scale(2)",
    color: "rgb(158, 59, 27)",
    margin: "20px 25px 0px 0px",
    float: "right",
  }

  const mobileheroimage = {
    backgroundImage: `url(${livingroom})`,
    backgroundPosition: "125% 50%",
    backgroundSize: "cover",
  }

    const heroSection = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const opacity = Math.max(0, 1 - window.scrollY / 1500);
            heroSection.current.style.opacity = opacity;
        };

        window.addEventListener('scroll', handleScroll);

        // Remove the event listener when the component is unmounted
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

  return (
    <div className="root">

      <div className="chd-wrapper">
      {/*......navbar and hero section */}

        <section>
          
          <nav className="mobilenav" style= {mobileheroimage}>

            <div className="chd-nav-header">
              <h1 id="chd-header-h1"> Comfort Home Decor </h1>
              <PiPackageFill style={closestyle}
                  className="packaged-closed"
                  onClick={()=>setShow(!show)}
              />
            </div>

            <div className="mobilehero"> 
              <h1 id="chd-mobilehero-header">Your Comfort, Your Home, Great Decor</h1>
              <h2 id="chd-mobilehero-line"></h2>
              <p id="chd-mobilehero-text">CHD offers style approaches that are unique to each client's taste, but with a heightened eye for design that lends to individual comfort and natural appeal.</p>
            </div>

          </nav>

          { show ?

          <div className="chd-nav-container-mobile-opened">
              <LuPackageOpen
                className="packaged-opened" 
                onClick={()=>setShow(false)}
              />
              <ul>
                <li className="chd-nav-item">
                  <Link className="active"
                    onClick={()=>setShow(false)}
                    to="/">
                      HOME
                  </Link>
                </li>
                <li className="chd-nav-item">
                  <HashLink className="chd-nav-link"
                    onClick={()=>setShow(false)}
                    smooth to="/#about">
                      ABOUT US
                  </HashLink>
                </li>
                <li className="chd-nav-item">
                  <Link className="chd-nav-link" 
                    onClick={()=>setShow(false)}
                    to="/services">
                      SERVICES
                  </Link>
                </li>
                <li className="chd-nav-item">
                  <Link className="chd-nav-link"
                    onClick={()=>setShow(false)}
                    to="/gallery">
                      GALLERY
                  </Link>
                </li>
            </ul>
          </div>

          : null}

          <nav className="chd-tabletplusnav" >
            <div className="chd-nav-tabletplus">
                <h1 id="tabletplus-header">Comfort Home Décor</h1>
                <ul id="chd-navitems-tabletplus">
                  <li id="chd-navitem-tabletplus-active">
                    <Link className="tabletplus-active"
                      onClick={()=>setShow(false)}
                      to="/">
                        HOME
                    </Link>
                  </li>
                  <li className="chd-navitem-tabletplus">
                    <Link className="chd-navlink-tabletplus" 
                      onClick={()=>setShow(false)}
                      to="/services">
                        SERVICES
                    </Link>
                  </li>
                  <li className="chd-navitem-tabletplus">
                    <Link className="chd-navlink-tabletplus"
                      onClick={()=>setShow(false)}
                      to="/gallery">
                        GALLERY
                    </Link>
                  </li>
                  <li className="chd-navitem-tabletplus">
                    <HashLink className="chd-navlink-tabletplus"
                      onClick={()=>setShow(false)}
                      smooth to="/#about">
                        ABOUT US
                    </HashLink>
                  </li>
                </ul>
              <div className="tp-hero" ref={heroSection}> 
                <h1 id="chd-tphero-header">All Comfort, Your Home, Great Décor</h1>
                <h2 id="chd-tphero-line"></h2>
                <p id="chd-tphero-text">CHD offers style approaches that are unique to each client's taste, but with a heightened eye for design that lends to individual comfort and natural appeal.</p>
              </div>
            </div>

          </nav>
          
        </section>
      
      {/*......need a revamp and our services section */}
        <section>

          <div className="chd-rabbits">

            <Slider />

          </div>

        </section>
      
      {/*......about section */}
        <section>
          <div id="about">

            <About />

          </div>
        {/*......footer section */}
          <footer>
            <Footer />
          </footer>
        </section>
        


      </div>
    </div>
  );
}

export default Landing;
