import './ButtonToTop.css';
import React, { useState, useEffect } from 'react';
import { SlArrowUp } from "react-icons/sl";

function ButtonToTop () {

    const [backToTopButton, setBackToTopButton] = useState(false);

        useEffect (() => {
            window.addEventListener("scroll", ()=> {
                if (window.scrollY > 100) 
                {
                    setBackToTopButton(true)
                } else { setBackToTopButton (false)
                }
            })
        });

        const scrollUp = ()=> {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            })
        };

return (
    
    <div className = "ToTop">
        {backToTopButton && (
            <button 
            onClick={scrollUp}
            id="button"
            >
                <SlArrowUp/>
            </button>
        )}
        
    </div>
);
}

export default ButtonToTop;