import './Navbar.css';
import logo from '../assets/logo.svg';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { PiPackageBold } from 'react-icons/pi';
import { LuPackageOpen} from 'react-icons/lu';
import React, { useState } from 'react';

function Navbar() {

  const [show, setShow] = useState(false);

  return (
  <div>
    <div className="nav-wrapper">
    {/*......navbar section */}

      <section>
				<nav className="chd-nav-header">
					<div>
					{/* <img className="chd-logo-image" src={logo}></img> */}
					<h1 id="chd-header-h1"> Comfort Home Decor </h1>
					</div>
				</nav>
				<div className="chd-nav-hidden">
					<PiPackageBold 
					className="packaged-closed"
					onClick={()=>setShow(!show)}
					/>
				</div>
        
        { show ?

        <div className="nav-nav-container-mobile-opened">
            <LuPackageOpen className="packaged-opened" 
              onClick={()=>setShow(false)}
            />
            <ul>
              <li className="nav-nav-item">
                <Link className="active"
                  onClick={()=>setShow(false)}
                  to="/">
                    HOME
                </Link>
              </li>
              <li className="nav-nav-item">
                <Link className="chd-nav-link" 
                  onClick={()=>setShow(false)}
                  to="/services">
                    SERVICES
                </Link>
              </li>
              <li className="nav-nav-item">
                <Link className="chd-nav-link"
                  onClick={()=>setShow(false)}
                  to="/gallery">
                    GALLERY
                </Link>
              </li>
              <li className="nav-nav-item">
                <HashLink className="chd-nav-link"
                  onClick={()=>setShow(false)}
                  smooth to="/#about">
                    ABOUT US
                </HashLink>
              </li>
          </ul>
        </div>

        : null}

      </section>

    {/*......end of navbar section */}

    </div>
  </div>
  );
}

export default Navbar;