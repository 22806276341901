import './About.css';
import React, { useState } from 'react';
import aboutimage from '../assets/img31.webp';
import { BsFacebook } from 'react-icons/bs';
import { BsInstagram } from 'react-icons/bs';
import { Link } from 'react-router-dom';



function About() {

return (
	<div className="chd-about-grid">
        <div className="about-grid-item about-image-container">
            <img className="about-image"src={aboutimage} alt="picture of bedroom"></img>
        </div>
        <div className="about-grid-item about-text">
            <h1>ABOUT <span>US</span> </h1>
            <h2>About Comfort Home Decor</h2>
            <p>
                Comfort Home Décor provides interior, exterior, and re-design services.
                CHD services offer residential and commercial owners design ideas and décor for spaces based on use, comfort level, functionality, and budget.
                CHD offers style approaches that are unique to each client’s taste,
                but with a heightened eye for design that lends to individual comfort and natural appeal.
                No room is off limits; from bathrooms, kitchens, to garages,
                CHD can accommodate and style with a strategic eye.
            </p>
            <div className="about-contact">
                <div className="socials">
                    <Link to="https://www.facebook.com/MyComfortHomeDecor" target="blank">
                        <BsFacebook id="facebook"/>
                    </Link>
                    <Link to="https://www.instagram.com/comforthomedecor/" target="blank">
                        <BsInstagram id = "instagram"/>
                    </Link>
                </div>
                <div className="contact-header">
                    <p>CONSULT | QUESTIONS | COMMENTS </p>
                    <p>PHONE | 504-330-3350</p>
                    <p>EMAIL | sontaef@gmail.com</p>
                </div>

            </div>
        </div>
	</div>
)}

export default About;