import React, { useState, useEffect, useRef, useCallback } from 'react';
import './GalleryRabbit.css';
// import { MdArrowBackIos } from 'react-icons/md';
import { MdArrowForwardIos } from 'react-icons/md';
import { Link } from 'react-router-dom';
import gallery from '../assets/gallery.webp';

function GalleryRabbit() {

  //transition for the entire gallery component
  const [transform, setTransform] = useState(200);

  useEffect(() => {
    const slideEffect = setInterval(() => {
    if (transform > 0) {
        setTransform(transform - 1);
    } else {
        clearInterval(slideEffect);
    }
    }, 3);

    return () => clearInterval(slideEffect);
    }, [transform]);

  //transition for the "Want to see more" div
  const [smallTransform, setSmallTransform] = useState(-100);

    useEffect(() => {
    const subSlideEffect = setInterval(() => {
    if (smallTransform < 0) {
        setSmallTransform(smallTransform + 1);
    } else {
        clearInterval(subSlideEffect);
    }
    }, 3);

    return () => clearInterval(subSlideEffect);
    }, [smallTransform]);

  //transition for the "check out our work" div
    const [smallTransform2, setSmallTransform2] = useState(100);

    useEffect(() => {
    const subSlideEffect2 = setInterval(() => {
    if (smallTransform2 > 0) {
        setSmallTransform2(smallTransform2 - 1);
    } else {
        clearInterval(subSlideEffect2);
    }
    }, 5);

    return () => clearInterval(subSlideEffect2);
    }, [smallTransform2]);


    return (
        <div>
            <div className="gallery-rabbit">
              <h2 className="g-h2" 
                style={{ transform: `translateY(${smallTransform}px)` }}
              >Want to see more?</h2>
              <h2 className="g-h2_2" style={{ transform: `translateY(${smallTransform2}px)` }} >Check out our work...</h2>
              <div className="g-pane">
                <img id="g-image" src={gallery}></img>
              </div>
              <Link className="g-button"
                  to="/gallery">
                    GALLERY
              </Link>
            </div>
        </div>
    );
}

export default GalleryRabbit;